"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/build-app/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAccount = createAccount;
exports.deleteItem = deleteItem;
exports.editAccount = editAccount;
exports.getAccountInfo = getAccountInfo;
exports.getAccountList = getAccountList;
exports.getDepList = getDepList;
exports.getList = getList;
exports.getRoleList = getRoleList;
exports.operaItem = operaItem;
require("core-js/modules/es6.function.name");
var _objectWithoutProperties2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/objectWithoutProperties.js"));
var _defineProperty2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _acountManagement = _interopRequireDefault(require("@services/acountManagement"));
var _department = _interopRequireWildcard(require("@services/department"));
var _role = _interopRequireDefault(require("@services/role"));
var _auth = require("@/utils/auth");
var _excluded = ["data"],
  _excluded2 = ["data"],
  _excluded3 = ["data"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var token = (0, _auth.getToken)();
function getAccountList(param) {
  return new Promise(function (resolve) {
    _acountManagement.default.getAccountList(_objectSpread(_objectSpread({}, param), {}, {
      token: token
    })).then(function (rv) {
      // AccountAPI.getAccountList({ ...param }).then(rv => {
      var code = rv.code;
      var _data = rv.data;
      if (code === 0) {
        var data = _data.data,
          others = (0, _objectWithoutProperties2.default)(_data, _excluded);
        resolve({
          pageInfo: others,
          data: data
        });
      }
    });
  });
}
function getList(param) {
  return new Promise(function (resolve) {
    _acountManagement.default.getList(_objectSpread(_objectSpread({}, param), {}, {
      token: token
    })).then(function (rv) {
      var code = rv.code;
      var _data = rv.data;
      if (code === 0) {
        var data = _data.data,
          others = (0, _objectWithoutProperties2.default)(_data, _excluded2);
        resolve({
          pageInfo: others,
          data: data
        });
      }
    });
  });
}
function getAccountInfo(id) {
  return new Promise(function (resolve) {
    _acountManagement.default.getInfo({
      id: id,
      token: token
    }).then(function (rv) {
      var code = rv.code,
        data = rv.data;
      if (code === 0) {
        resolve(data);
      } else {
        resolve(null);
      }
    });
  });
}
function createAccount(param) {
  return new Promise(function (resolve, reject) {
    _acountManagement.default.add(param).then(function (rv) {
      var code = rv.code,
        msg = rv.msg,
        data = rv.data;
      if (code === 0) {
        resolve({
          data: data
        });
      } else {
        reject(msg);
      }
    });
  });
}
function editAccount(param) {
  return new Promise(function (resolve, reject) {
    _acountManagement.default.edit(param).then(function (rv) {
      var code = rv.code,
        msg = rv.msg;
      if (code === 0) {
        resolve();
      } else {
        reject(msg);
      }
    });
  });
}
function deleteItem(id) {
  return new Promise(function (resolve, reject) {
    _acountManagement.default.del({
      id: id
    }).then(function (rv) {
      var code = rv.code,
        msg = rv.msg;
      if (code === 0) {
        resolve();
      } else {
        reject(msg);
      }
    });
  });
}
function getRoleList() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return new Promise(function (resolve) {
    _role.default.getList({
      size: 999,
      page: 1,
      name: name,
      token: token
    }).then(function (rv) {
      var code = rv.code;
      var _data = rv.data;
      if (code === 0) {
        var data = _data.data,
          others = (0, _objectWithoutProperties2.default)(_data, _excluded3);
        resolve({
          pageinfo: others,
          data: data
        });
      }
    });
  });
}
function getDepList() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return new Promise(function (resolve) {
    _department.default.getList({
      size: 300,
      page: 1,
      name: name,
      token: token
    }).then(function (rv) {
      var code = rv.code,
        data = rv.data;
      if (code === 0) {
        var list = (0, _department.adapter)(data);
        resolve({
          list: list,
          origin: data.map(function (e) {
            return {
              label: e.name,
              id: e.id
            };
          })
        });
      }
    });
  });
}
function operaItem(row) {
  return new Promise(function (resolve, reject) {
    _acountManagement.default.opera(row).then(function (rv) {
      var code = rv.code,
        msg = rv.msg;
      if (code === 0) {
        resolve();
      } else {
        reject(msg);
      }
    });
  });
}