var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c("div", {
      staticClass: "iconfont icon-lingdang",
      staticStyle: { "font-size": "23px" },
    }),
    _vm.unregulatedTotal > 0
      ? _c("div", { staticClass: "signStyle" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.unregulatedTotal > 99 ? "99+" : _vm.unregulatedTotal) +
              "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }