"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
/**
 * 角色权限处理
 */
var _default = exports.default = {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var super_admin = 'admin';
    var roles = _store.default.getters && _store.default.getters.roles;
    if (value && value instanceof Array && value.length > 0) {
      var roleFlag = value;
      var hasRole = roles.some(function (role) {
        return super_admin === role || roleFlag.includes(role);
      });
      if (!hasRole) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      var lang = localStorage.getItem('language') || 'zh';
      throw new Error(lang == 'zh' ? '请设置角色权限标签值' : lang == 'en' ? 'Please set the role permission tag value' : 'Vui lòng thiếp lập quyền hạn cho vai trò đánh dấu');
    }
  }
};