var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.titleName,
        visible: _vm.dialogFlag,
        "close-on-click-modal": false,
        width: "420",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFlag = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-width": "150px", rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("oldPassword"),
                width: "300px",
                prop: "code",
              },
            },
            [
              _c("el-input", {
                attrs: { "show-password": "" },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "code", $$v)
                  },
                  expression: "form.code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("newPassword"), prop: "password" } },
            [
              _c("el-input", {
                attrs: { "show-password": "" },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("confirmPassword"), prop: "again_pwd" } },
            [
              _c("el-input", {
                attrs: { "show-password": "" },
                model: {
                  value: _vm.form.again_pwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "again_pwd", $$v)
                  },
                  expression: "form.again_pwd",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.$t("cancel"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }