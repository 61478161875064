var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "700px",
        title: _vm.closeTitle,
        visible: _vm.innerVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.innerVisible = $event
        },
        close: _vm.innerCancel,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-width": "180px",
                model: _vm.form,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("remark") } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("pleaseEnter"),
                      maxlength: 5000,
                    },
                    model: {
                      value: _vm.form.public_mark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "public_mark", $$v)
                      },
                      expression: "form.public_mark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("privateRemark") } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("pleaseEnter"),
                      maxlength: 5000,
                    },
                    model: {
                      value: _vm.form.private_mark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "private_mark", $$v)
                      },
                      expression: "form.private_mark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.innerCancel } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("cancel")) + "\n    "),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.innerConfirm } },
            [_vm._v("\n      " + _vm._s(_vm.$t("confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }