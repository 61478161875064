var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "450px",
        "append-to-body": "",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-width": "140px" },
        },
        [
          _vm.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("groupName"), prop: "group_name" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("pleaseInputGroupNameValue"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onInput()
                      },
                    },
                    model: {
                      value: _vm.form.group_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "group_name", $$v)
                      },
                      expression: "form.group_name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.type == 2
            ? _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("dealDocument"), prop: "group_name" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("inputDocumentName"),
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onInput()
                      },
                    },
                    model: {
                      value: _vm.form.group_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "group_name", $$v)
                      },
                      expression: "form.group_name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v(_vm._s(_vm.$t("cancel"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading_A },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }