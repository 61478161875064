"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Notice",
  data: function data() {
    return {
      // number: this.updateNumber
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(["unregulatedTotal"])),
  watch: {
    unregulatedTotal: {
      handler: function handler(val) {
        this.handleIcoCreate(val);
      },
      deep: true,
      immediate: true
    }
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {},
  methods: {
    handleIcoCreate: function handleIcoCreate(val) {
      var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = val > 0 ? "tip-red.png" : "logo.png";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }
};