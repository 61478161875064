"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  roleIds: function roleIds(state) {
    return state.user.roleIds;
  },
  permissions: function permissions(state) {
    return state.user.permissions;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  updateNumber: function updateNumber(state) {
    return state.user.updateNumber;
  },
  updateNumberMessage: function updateNumberMessage(state) {
    return state.user.updateNumberMessage;
  },
  unregulatedTotal: function unregulatedTotal(state) {
    return state.permission.unregulatedTotal;
  },
  groupList: function groupList(state) {
    return state.permission.groupList;
  },
  overList: function overList(state) {
    return state.user.overList;
  },
  treeArray: function treeArray(state) {
    return state.user.treeArray;
  },
  treeArray2: function treeArray2(state) {
    return state.user.treeArray2;
  },
  permission: function permission(state) {
    return state.user.permission;
  },
  roleListArr: function roleListArr(state) {
    return state.user.roleListArr;
  },
  // 工单列表请求
  navMenuList: function navMenuList(state) {
    return state.activity.navMenuList;
  },
  sysPanelCreateArr: function sysPanelCreateArr(state) {
    return state.activity.sysPanelCreateArr;
  },
  isPinOver: function isPinOver(state) {
    return state.permission.isPinOver;
  },
  // 工单模块数据
  workSearchListArr: function workSearchListArr(state) {
    return state.activity.workSearchListArr;
  },
  classArrList: function classArrList(state) {
    return state.activity.classArrList;
  },
  // 站点id
  siteUrlData: function siteUrlData(state) {
    return state.activity.siteUrlData;
  },
  // 推单操作
  Backlog_num: function Backlog_num(state) {
    return state.user.Backlog_num;
  },
  Pending_num: function Pending_num(state) {
    return state.user.Pending_num;
  },
  InHand_num: function InHand_num(state) {
    return state.user.InHand_num;
  },
  ToBeConfirmed_num: function ToBeConfirmed_num(state) {
    return state.user.ToBeConfirmed_num;
  },
  ifCache: function ifCache(state) {
    return state.user.ifCache;
  },
  // ===
  pendingArr: function pendingArr(state) {
    return state.user.pendingArr;
  }
};
var _default = exports.default = getters;