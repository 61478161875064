var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews, exclude: _vm.exclude } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("warning"),
            visible: _vm.dialogVisible,
            width: "30%",
            modal: "",
            "modal-append-to-body": "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("authChange")))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.exitLogin } },
                [_vm._v(_vm._s(_vm.$t("logout")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c("audio", {
        ref: "dingdong",
        attrs: { preload: "metadata", src: require("@/assets/dingdong.wav") },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }