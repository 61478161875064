"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    // isOpen: {
    //   type: String,
    //   default: ''
    // },
    isOpen: {
      type: Boolean,
      default: false
    },
    meta: {
      type: Object
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      isOpen = _context$props.isOpen,
      meta = _context$props.meta;
    var vNodes = [];
    if (isOpen && icon) {
      vNodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }
    if (title) {
      vNodes.push(h("span", {
        "slot": 'title'
      }, [title]));
    }
    if (meta) {
      if (title && meta.langKey === 'pending' && Number(meta.many) > 0) {
        vNodes.push(h("span", {
          "slot": 'title'
        }, [h("span", {
          "class": 'icon'
        }, [meta.many > 99 ? '99+' : meta.many])]));
      }
    }
    return vNodes;
  }
};