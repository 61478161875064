var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "700px",
        title: _vm.$t("revise"),
        visible: _vm.innerVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.innerVisible = $event
        },
        close: _vm.innerCancel,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-width": "180px",
                model: _vm.form,
              },
            },
            [
              _vm._l(_vm.formValue, function (value, key, index) {
                return _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: value.type == "text",
                        expression: "value.type == 'text'",
                      },
                    ],
                    key: index,
                    attrs: { label: value.title },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: value.value_two,
                        callback: function ($$v) {
                          _vm.$set(value, "value_two", $$v)
                        },
                        expression: "value.value_two",
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._l(_vm.formValue, function (value, key, index) {
                return _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: value.type == "select",
                        expression: "value.type == 'select'",
                      },
                    ],
                    key: index,
                    attrs: { label: value.title },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: _vm.$t("pleaseSelectActivityArea"),
                        },
                        model: {
                          value: value.value_two,
                          callback: function ($$v) {
                            _vm.$set(value, "value_two", $$v)
                          },
                          expression: "value.value_two",
                        },
                      },
                      _vm._l(value.values, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.label, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.formValue, function (value, key, index) {
                return _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: value.type == "datetime",
                        expression: "value.type == 'datetime'",
                      },
                    ],
                    key: index,
                    attrs: { label: value.title },
                  },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetime",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        format: "yyyy-MM-dd HH:mm:ss",
                        placeholder: _vm.$t("pleaseSelectDateTime"),
                        align: "center",
                      },
                      model: {
                        value: value.value_two,
                        callback: function ($$v) {
                          _vm.$set(value, "value_two", $$v)
                        },
                        expression: "value.value_two",
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._l(_vm.formValue, function (value, key, index) {
                return _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: value.type == "datetimerange",
                        expression: "value.type == 'datetimerange'",
                      },
                    ],
                    key: index,
                    attrs: { label: value.title },
                  },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        format: "yyyy-MM-dd HH:mm:ss",
                        placeholder: _vm.$t("pleaseSelectDateTime"),
                        align: "center",
                      },
                      model: {
                        value: value.value_two,
                        callback: function ($$v) {
                          _vm.$set(value, "value_two", $$v)
                        },
                        expression: "value.value_two",
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._l(_vm.formValue, function (value, key, index) {
                return _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: value.type == "date",
                        expression: "value.type == 'date'",
                      },
                    ],
                    key: index,
                    attrs: { label: value.title },
                  },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        placeholder: _vm.$t("pleaseSelectDateTime"),
                        align: "center",
                      },
                      model: {
                        value: value.value_two,
                        callback: function ($$v) {
                          _vm.$set(value, "value_two", $$v)
                        },
                        expression: "value.value_two",
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._l(_vm.formValue, function (value, key, index) {
                return _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: value.type == "textarea",
                        expression: "value.type == 'textarea'",
                      },
                    ],
                    key: index,
                    attrs: { label: value.title },
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea" },
                      model: {
                        value: value.value_two,
                        callback: function ($$v) {
                          _vm.$set(value, "value_two", $$v)
                        },
                        expression: "value.value_two",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("imageUpload") + ":" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            limit: 10,
                            action: _vm.uploadUtil.apiUrl,
                            name: "file",
                            "list-type": "picture-card",
                            data: _vm.uploadData,
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.handleSuccess,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-exceed": _vm.handleExceed,
                            multiple: "",
                            "file-list": _vm.imageList,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              _vm.imageList = $event
                            },
                            "update:file-list": function ($event) {
                              _vm.imageList = $event
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            limit: 10,
                            drag: "",
                            action: _vm.uploadUtil.apiUrl,
                            name: "file",
                            data: _vm.uploadData,
                            multiple: "",
                            "on-exceed": _vm.handleExceed,
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.handleSuccess,
                            "file-list": _vm.imageList,
                          },
                          on: {
                            "update:fileList": function ($event) {
                              _vm.imageList = $event
                            },
                            "update:file-list": function ($event) {
                              _vm.imageList = $event
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("dragFilesHereOrClickUpload"))
                            ),
                            _c("em", [
                              _vm._v(" " + _vm._s(_vm.$t("clickUpload"))),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("priority") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("pleaseSelect") },
                      model: {
                        value: _vm.form.urgent_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "urgent_status", $$v)
                        },
                        expression: "form.urgent_status",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("remark") } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t("pleaseEnter"),
                      maxlength: 5000,
                    },
                    model: {
                      value: _vm.form.workorder_des,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "workorder_des", $$v)
                      },
                      expression: "form.workorder_des",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.innerCancel } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("cancel")) + "\n    "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btnLoading },
              on: { click: _vm.innerConfirm },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }