"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App'
  // https://oa2-test.manbetx.tk
  // VUE_APP_BASE_API_ADMIN = 'http://192.168.1.33:10010'
};