"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _groupManage = _interopRequireDefault(require("@services/groupManage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "CreateAccount",
  components: {},
  props: ["visible", "editItem", "siteName", "type"],
  data: function data() {
    return {
      loading_A: false,
      loading: false,
      showPassword: false,
      departmentOption: [],
      users: [],
      form: {
        siteName: localStorage.getItem("site_name"),
        group_name: "",
        site_list_id: localStorage.getItem("siteId")
      },
      rules: {
        group_name: [{
          required: true,
          message: this.$t("pleaseInputGroupNameValue"),
          trigger: "blur"
        }]
      }
    };
  },
  computed: {
    title: function title() {
      return this.editItem ? this.$t("edit") : this.$t("new");
    }
  },
  watch: {},
  mounted: function mounted() {
    if (this.editItem) {
      this.form.group_name = this.editItem.group_name;
    }
  },
  methods: {
    onInput: function onInput() {
      // input输入框无法编辑
      this.$forceUpdate();
    },
    handleClose: function handleClose() {
      this.$emit("update:visible", false);
    },
    submit: function submit() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.loading_A = true;
          console.log(_this.editItem);
          if (_this.editItem) {
            var params = {
              status: _this.editItem.status,
              group_name: _this.form.group_name,
              type: _this.type
            };
            _groupManage.default.compile(_this.editItem.id, params).then(function (res) {
              var code = res.code,
                msg = res.msg;
              if (code !== 200) {
                return _this.$message.error(msg);
              }
              _this.$message.success(_this.$t("editSuccess"));
              _this.loading_A = false;
              _this.$emit("update:visible", false);
              _this.$emit("updated");
            });
          } else {
            var _params = {
              site_list_id: _this.form.site_list_id,
              group_name: _this.form.group_name,
              type: _this.type
            };
            _groupManage.default.addGroup(_params).then(function (res) {
              var code = res.code,
                msg = res.msg;
              if (code !== 200) {
                return _this.$message.error(msg);
              }
              _this.$message.success(_this.$t("createSuccess"));
              _this.loading_A = false;
              _this.$emit("update:visible", false);
              _this.$emit("updated");
            });
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    }
  }
};