var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c(
        "div",
        { staticClass: "menu-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  return _vm.handleMenu()
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("menuToggle")) + "\n    ")]
          ),
        ],
        1
      ),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c(
        "div",
        { staticClass: "overtime" },
        [
          _c("div", { staticClass: "box" }, [
            _vm._v("\n      超时工单\n      "),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("\n        " + _vm._s(_vm.totalData) + "\n      "),
            ]),
            _vm._v("\n      条\n    "),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.showOvertime },
            },
            [_vm._v("查看")]
          ),
          _c(
            "div",
            { staticClass: "switchtext" },
            [
              _c("div", [_vm._v("超时工单是否置顶：")]),
              _c("el-switch", {
                on: { change: _vm.checkChange },
                model: {
                  value: _vm.checkVal,
                  callback: function ($$v) {
                    _vm.checkVal = $$v
                  },
                  expression: "checkVal",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: _vm.$t("layoutSize"),
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" },
                    }),
                  ],
                  1
                ),
                _c("notice", {
                  staticClass: "right-menu-item",
                  attrs: { id: "notice" },
                }),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "lang-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
              on: { command: _vm.setLanguage },
            },
            [
              _c("div", { staticClass: "lang-wrapper" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("language")) + "\n        "
                ),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "zh" } }, [
                    _vm._v(" 中文 "),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "en" } }, [
                    _vm._v(" English "),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "vn" } }, [
                    _vm._v(" Tiếng Việt "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
              on: { command: _vm.handleCommandMenu },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar },
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "a" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("passwordReset")))]),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "b" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("logout")))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("reset-code", { ref: "reset" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "超时工单",
            visible: _vm.dialogTableVisible,
            width: "980px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              key: _vm.maxHeight,
              ref: "multipleTable",
              class: "multipleTable" + _vm.sysPanelListIndex,
              staticStyle: { width: "100%" },
              attrs: { data: _vm.displayTable, "row-style": _vm.tableRowName },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ticketNumber"),
                  prop: "order_number",
                  "min-width": "110",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.order_number))]),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:copy",
                                value: scope.row.order_number,
                                expression: "scope.row.order_number",
                                arg: "copy",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:success",
                                value: _vm.onCopy,
                                expression: "onCopy",
                                arg: "success",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:error",
                                value: _vm.onError,
                                expression: "onError",
                                arg: "error",
                              },
                            ],
                            attrs: { type: "primary", size: "mini" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("copy")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ticketIssue"),
                  prop: "question_name",
                  "min-width": "110",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.attr.question_name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("currentStatus"),
                  prop: "workorder_status",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.workStatusTable(scope.row.workorder_status)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ticketApplicationTime"),
                  prop: "workorder_time",
                  "min-width": "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.time.workorder_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("ticketPeriod"),
                  prop: "day",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.time.day))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("submittedBy"),
                  align: "center",
                  prop: "apply_user_id_nickname",
                  "min-width": "90",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("processBy"),
                  align: "center",
                  prop: "close_user_id_nickname",
                  "min-width": "90",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operate"),
                  width: "120",
                  align: "center",
                  fixed: "right",
                  "class-name": ["operation", "row-field"].join(" "),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-dropdown",
                          { attrs: { placement: "bottom" } },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "fade" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    nativeOn: {
                                      mouseout: function ($event) {
                                        return _vm.resetOperaBtn($event)
                                      },
                                      click: function ($event) {
                                        return _vm.getOperaBtn(
                                          0,
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("operate")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              _vm._l(scope.row.batchBtn, function (item, key) {
                                return _c(
                                  "el-dropdown-item",
                                  {
                                    key: key,
                                    attrs: { command: "next" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleCommand(
                                          key,
                                          1,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "marb" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "txt",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetails(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("details")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { type: "flex", justify: "end" },
            },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [20, 50, 100, 200, 500],
                  "page-size": _vm.currentPageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.totalData,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("transfer"),
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "40%", "margin-left": "25%" },
              attrs: {
                placeholder: _vm.$t("pleaseInputUserName"),
                clearable: "",
              },
              model: {
                value: _vm.roleData.username,
                callback: function ($$v) {
                  _vm.$set(_vm.roleData, "username", $$v)
                },
                expression: "roleData.username",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.searchusername },
                slot: "append",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "25%", "margin-top": "30px" } },
            [
              _c("el-tree", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.treeLoading,
                    expression: "treeLoading",
                  },
                ],
                ref: "tree",
                attrs: {
                  "show-checkbox": "",
                  data: _vm.roleUserData,
                  "node-key": "id",
                  "default-expanded-keys": _vm.unfoldRole,
                  props: _vm.defaultProps,
                  "check-strictly": true,
                  "default-expand-all": false,
                },
                on: { check: _vm.getname },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("cancel")) + "\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading_transfer },
                  on: { click: _vm.transferComfirm },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("confirm")) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CloseDialog", { ref: "close" }),
      _c("UnsolvedDialog", { ref: "unsolved" }),
      _c("EditDialog", { ref: "edit" }),
      _c("look-detail", { ref: "detailDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }