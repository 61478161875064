"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _defineProperty2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("/build-app/node_modules/core-js/modules/es6.array.iterator.js");
require("/build-app/node_modules/core-js/modules/es6.promise.js");
require("/build-app/node_modules/core-js/modules/es6.object.assign.js");
require("/build-app/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _dayjs = _interopRequireDefault(require("dayjs"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./assets/styles/element-variables.scss");
require("@/assets/styles/index.scss");
require("@/assets/styles/common.scss");
require("@/assets/font/style.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _permission = _interopRequireDefault(require("./directive/permission"));
require("./assets/icons");
require("./permission");
var _common2 = require("@/utils/common");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // import Cookies from 'js-cookie'
// a modern alternative to CSS resets
// global css
// 点击窗口移动的指令
// import drag from './utils/drag'
// icon
// permission control
// 复制插件方法
_vue.default.use(_vueClipboard.default);
_vue.default.use(_vueI18n.default);
// 全局方法挂载
_vue.default.prototype.parseTime = _common2.parseTime;
_vue.default.prototype.resetForm = _common2.resetForm;
_vue.default.prototype.addDateRange = _common2.addDateRange;
_vue.default.prototype.selectDictLabel = _common2.selectDictLabel;
_vue.default.prototype.download = _common2.download;
_vue.default.prototype.dayjs = _dayjs.default;
_vue.default.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  });
};
_vue.default.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  });
};
_vue.default.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

// 全局组件挂载
_vue.default.component('Pagination', _Pagination.default);
_vue.default.use(_permission.default);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
var lang = localStorage.getItem('language') || 'zh';
var _i18n = new _vueI18n.default({
  locale: lang,
  // 语言标识
  messages: {
    'zh': _objectSpread(_objectSpread({}, require("./assets/lang/zh")), _zhCN.default),
    'en': _objectSpread(_objectSpread({}, require("./assets/lang/en")), _en.default),
    'vn': _objectSpread(_objectSpread({}, require("./assets/lang/vn")), _vi.default)
  }
});
_vue.default.use(_elementUi.default, {
  size: 'small',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
_vue.default.config.productionTip = false;
_vue.default.config.devtools = true;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _i18n,
  render: function render(h) {
    return h(_App.default);
  }
});