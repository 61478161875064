var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("details"),
        visible: _vm.innerVisible,
        "show-close": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        width: "87%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.innerVisible = $event
        },
      },
    },
    [
      _c("div", {
        staticClass: "closeStyle el-icon-error",
        on: {
          click: function ($event) {
            _vm.innerVisible = false
          },
        },
      }),
      _c("div", { staticClass: "app-container" }, [
        _c(
          "div",
          { staticClass: "app-list" },
          [
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("ticketNumber"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.order_number)),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("ticketModule"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.config_name)),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("ticketClassification"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.type_name)),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("ticketIssue"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.question_name)),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("permissionGroup"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.create_group)),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("submittedBy"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(
                  _vm._s(
                    _vm.form.apply_user_id_nickname
                      ? _vm.form.apply_user_id_nickname
                      : _vm.form.apply_user_id
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("operationNow"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.optid)),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("priority"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.statusTxt(_vm.form.urgent_status))),
              ]),
            ]),
            _vm._l(_vm.form.fieldMsg, function (item, key) {
              return _c("div", { key: key }, [
                key == "会员账号"
                  ? _c("div", { staticClass: "tableStyle" }, [
                      _c(
                        "span",
                        {
                          class:
                            _vm.form.player_account_levinfo.isQuality == 1
                              ? "tableStyle_1 orange"
                              : "tableStyle_1",
                        },
                        [_vm._v(_vm._s(key))]
                      ),
                      _c(
                        "span",
                        {
                          class:
                            _vm.form.player_account_levinfo.isQuality == 1
                              ? "tableStyle_2 orange"
                              : "tableStyle_2",
                        },
                        [_vm._v(_vm._s(item))]
                      ),
                    ])
                  : _c("div", { staticClass: "tableStyle" }, [
                      _c("span", { staticClass: "tableStyle_1" }, [
                        _vm._v(_vm._s(key)),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "tableStyle_2",
                          staticStyle: { width: "100px" },
                        },
                        [_vm._v(_vm._s(item))]
                      ),
                    ]),
              ])
            }),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("ticketApplicationTime"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.workorder_time)),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("currentStatus"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.curStatusTxt(_vm.form.workorder_status))),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("ticketApplication"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.workorder_des || "")),
              ]),
            ]),
            _c("div", { staticClass: "tableStyle tableStyleEnd" }, [
              _c("span", { staticClass: "tableStyle_1" }, [
                _vm._v(_vm._s(_vm.$t("remark"))),
              ]),
              _c("span", { staticClass: "tableStyle_2" }, [
                _vm._v(_vm._s(_vm.form.workorder_des || "")),
              ]),
            ]),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "previewPic" },
          [
            _c(
              "el-image",
              {
                staticStyle: { width: "380px", height: "445px" },
                attrs: { src: _vm.previewPicSrc },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error",
                  },
                  [
                    _c("div", [
                      _c("i", { staticClass: "el-icon-picture-outline" }),
                      _c("br"),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("dataNotPictureRemark")) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("p", [_vm._v(_vm._s(_vm.$t("processingRecords")))]),
      _c(
        "el-table",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("serialNumber"),
              align: "center",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v("\n        " + _vm._s(row.id) + "\n      ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("action"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v("\n        " + _vm._s(row.type) + "\n        "),
                    row.type === "修改" ||
                    row.type === "Revise" ||
                    row.type === "Sửa đổi"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editDetail(row.update_text)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("reviseDetail")))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("operation"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v("\n        " + _vm._s(row.mark_user) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("group"), align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v("\n        " + _vm._s(row.mark_group) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("status"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.curStatusTxt(row.status)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("monitoringTeam"),
              align: "center",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(row.treatment_group || "") +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("description"),
              align: "center",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.public_mark
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("publicRemark")) +
                              "：" +
                              _vm._s(row.public_mark || "") +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    row.private_mark
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("privateRemark")) +
                              "：" +
                              _vm._s(row.private_mark || "") +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("imageRemarks"),
              align: "center",
              width: "210",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.image_mark
                      ? _c(
                          "div",
                          _vm._l(
                            JSON.parse(row.image_mark),
                            function (item, index) {
                              return _c("el-image", {
                                key: index,
                                staticStyle: { width: "38px", height: "38px" },
                                attrs: {
                                  src: item.url,
                                  "preview-src-list": _vm.srcList(
                                    JSON.parse(row.image_mark)
                                  ),
                                },
                              })
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("time"), align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v("\n        " + _vm._s(row.created_at) + "\n      "),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "60%",
            title: _vm.$t("editContent"),
            visible: _vm.insideHide,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.insideHide = $event
            },
          },
        },
        [
          _vm._l(_vm.editDetailData, function (item, key) {
            return _c(
              "el-form",
              {
                key: key,
                attrs: {
                  model: _vm.formData,
                  "label-width": "130px",
                  "label-suffix": ":",
                },
              },
              [
                _c("el-form-item", { attrs: { label: key } }, [
                  _c("span", [_vm._v(_vm._s(item.new))]),
                  _c("br"),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(_vm.$t("rawData")) + ":" + _vm._s(item.old)),
                  ]),
                ]),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.insideHide = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }