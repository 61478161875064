"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.to-string");
var _defineProperty2 = _interopRequireDefault(require("/build-app/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _mqtt = _interopRequireDefault(require("mqtt"));
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash.debounce"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AppMain",
  data: function data() {
    return {
      // 消息订阅
      topic: process.env.VUE_APP_TOPIC_NOTICE,
      client: null,
      //
      dialogVisible: false,
      userName: localStorage.getItem("userName"),
      exclude: []
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(["Backlog_num", "Pending_num", "InHand_num", "ToBeConfirmed_num", "ifCache"])), {}, {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  }),
  watch: {
    ifCache: function ifCache(value, old) {
      this.exclude = [];
    },
    Backlog_num: function Backlog_num(value, old) {
      if (old === -1) {
        this.exclude = [];
        return;
      }
      if (value > old) {
        this.exclude = ["Pending", "Backlog"];
      }
    },
    Pending_num: function Pending_num(value, old) {
      if (old === -1) {
        this.exclude = [];
        return;
      }
      if (value > old) {
        this.exclude = ["Pending", "Backlog"];
      }
    },
    InHand_num: function InHand_num(value, old) {
      if (old === -1) {
        this.exclude = [];
        return;
      }
      if (value > old) {
        this.exclude = ["InHand"];
      }
    },
    ToBeConfirmed_num: function ToBeConfirmed_num(value, old) {
      if (old === -1) {
        this.exclude = [];
        return;
      }
      if (value > old) {
        this.exclude = ["ToBeConfirmed"];
      }
    }
  },
  created: function created() {
    this.dialogVisible = false;
  },
  mounted: function mounted() {
    this.exclude = [];
    // 创建mqtt链接
    this.createConnection();
  },
  destroyed: function destroyed() {
    this.exclude = [];
    this.closeConnection(); // 页面销毁后断开websocket连接
  },
  methods: {
    // 退出登录
    exitLogin: function exitLogin() {
      this.dialogVisible = false;
      this.$store.dispatch("LogOut").then(function () {
        location.reload();
      });
    },
    createConnection: function createConnection() {
      var _this = this;
      this.client = _mqtt.default.connect(process.env.VUE_APP_MQTT_URL);
      // 订阅
      this.client.on("connect", function () {
        // 开始订阅
        _this.client.subscribe(_this.topic, function (error) {
          if (error) {
            return;
          }
        });
      });
      // 回调
      this.client.on("message", function (topic, message) {
        var data = JSON.parse(JSON.parse(message.toString()));
        var site_id = localStorage.getItem("siteId");
        // console.log("message", data, site_id);
        // 如果不是当前账号相关的推送，不进行更新
        // if (site_id != data.siteId) {
        //   return;
        // }

        var user_name = localStorage.getItem("name");
        var roleIdsName = localStorage.getItem("roleIdsName");
        var permissions_ids = localStorage.getItem("permissions_ids");
        if (data.user && data.user === user_name) {
          console.log("消息来啦1111");
          // this.$notify({
          //   title: this.$t('hint'),
          //   type: 'warning',
          //   message: data.title
          // })
          // this.$nextTick(() => {
          //   // this.$refs['dingdong'].play()
          //   this.$refs.dingdong.play()
          // })
          // this.$store.dispatch('getNumber')
          _this.$store.dispatch("getMessageNumber");
          // this.$store.dispatch('getPending', 1)
          return;
        } else {
          var oldNum = roleIdsName.split(",");
          var newNum = data.groups.split(",");
          // console.log(newNum, oldNum);
          oldNum.map(function (item) {
            if (newNum.includes(item)) {
              var dataType = Number(data.type);
              if (dataType === 1) {
                _this.$notify({
                  title: _this.$t("hint"),
                  type: "warning",
                  message: data.title
                });
                _this.$nextTick(function () {
                  _this.$refs["dingdong"].play();
                });
              }
              _this.newfun();
              // this.$store.dispatch("getMessageNumber");

              // 100:增加了超时工单  101:取消标记观察工单  102:标记观察工单 4:标为已解决 5:确认关闭 7:关闭
              if ([100, 101, 102, 5, 4].includes(dataType)) {
                if (dataType === 100) {
                  sessionStorage.setItem("messagetype", 100);
                } else {
                  sessionStorage.setItem("messagetype", 1000);
                }
                _this.func();
                // this.$store.dispatch("getOvertime");
              }
              return;
            }
          });
        }
        // 单一模板请求
        if (data.permissions && data.workorder_cate) {
          var permissions_old = permissions_ids.split(",");
          var permissions_new = data.permissions;
          permissions_old.map(function (item) {
            if (permissions_new.includes(item)) {
              if (Number(data.workorder_cate) === Number(sessionStorage.sysPanelListIndex) || Number(sessionStorage.sysPanelListIndex) === 999) {
                console.log("消息来啦3333");
                // this.$notify({
                //   title: this.$t('hint'),
                //   type: 'warning',
                //   message: data.title
                // })
                // this.$nextTick(() => {
                // this.$refs['dingdong'].play()
                // this.$refs.dingdong.play()
                // })
                _this.$store.dispatch("getMessageNumber");
                _this.$store.dispatch("getPending", 1);
                return;
              }
            }
          });
        }
      });
    },
    func: (0, _lodash.default)(function () {
      this.$store.dispatch("getOvertime");
    }, 3000),
    newfun: (0, _lodash.default)(function () {
      this.$store.dispatch("getMessageNumber");
    }, 3000),
    // 断开连接
    closeConnection: function closeConnection() {
      if (this.client) {
        try {
          this.client.end();
          this.client = null;
        } catch (error) {
          console.log(error, "断开链接错误");
        }
      }
    }
  }
};