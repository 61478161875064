var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "errPage-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "pan-back-btn",
          attrs: { icon: "arrow-left" },
          on: { click: _vm.back },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("return")) + "\n  ")]
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("notAuthToAccess1")) + "\n      "
              ),
            ]),
            _c("h2", [_vm._v(_vm._s(_vm.$t("notAuthToAccess2")))]),
            _c("h6", [_vm._v(_vm._s(_vm.$t("notAuthToAccess3")))]),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c(
                "li",
                { staticClass: "link-type" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("returnHome")) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              attrs: {
                src: _vm.errGif,
                width: "313",
                height: "428",
                alt: "Girl has dropped her ice cream.",
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }