"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  dealDocument: "",
  followUpGroup: ""
};
var mutations = {
  SET_DEAL_DOCUMNENT: function SET_DEAL_DOCUMNENT(state, val) {
    state.dealDocument = val;
  },
  FOLLOW_UP_GROUP: function FOLLOW_UP_GROUP(state, val) {
    state.followUpGroup = val;
  }
};
var actions = {
  setDealDocument: function setDealDocument(_ref, val) {
    var commit = _ref.commit;
    commit('SET_DEAL_DOCUMNENT', val);
  },
  setFollowUpGroup: function setFollowUpGroup(_ref2, val) {
    var commit = _ref2.commit;
    commit('FOLLOW_UP_GROUP', val);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};