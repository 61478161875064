var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo }, staticStyle: { display: "flex" } },
    [
      _vm.showLogo ? _c("logo") : _vm._e(),
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: {
            "default-active": _vm.activeMenu,
            "background-color": _vm.variables.menuBg,
            "text-color": _vm.variables.menuText,
            "unique-opened": true,
            "active-text-color": _vm.variables.menuActiveText,
            mode: "horizontal",
          },
        },
        _vm._l(_vm.permission_routes, function (route) {
          return _c("sidebar-item", {
            key: route.path,
            staticStyle: { "padding-left": "15px" },
            attrs: { item: route, "base-path": route.path },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }