"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _router2 = require("@/router");
var _index = _interopRequireDefault(require("@/layout/index"));
var _system = _interopRequireDefault(require("@services/system"));
var _WorkOrderEntity = _interopRequireDefault(require("@services/WorkOrderEntity"));
var _WorkOrderQues = _interopRequireDefault(require("@services/WorkOrderQues"));
var _groupManage = _interopRequireDefault(require("@services/groupManage"));
// import store from "@/store";
// import { series } from 'async'

var _router = {
  msg: '操作成功',
  code: 200,
  data: [{
    name: 'WorkOrderListAllList',
    path: '/work_order_list',
    component: 'Layout',
    meta: {
      title: '工单列表',
      icon: 'example',
      langKey: 'ticketList'
    },
    children: [{
      name: 'WorkOrderListAll',
      path: '/work_order_list',
      component: 'WorkOrderList/pages/All',
      meta: {
        title: '工单列表',
        icon: 'system',
        noCache: true,
        langKey: 'ticketList'
      }
    }, {
      name: 'MyCreate',
      path: '/work_order_create',
      component: 'WorkOrderList/pages/All/MyCreate',
      meta: {
        title: '我创建的',
        icon: 'system',
        langKey: 'createdByMe'
      }
    }, {
      name: 'Backlog',
      path: '/work_order_backlog',
      component: 'WorkOrderList/pages/All/Backlog',
      meta: {
        title: '待我处理',
        icon: 'system',
        // sign: true,
        num: 'my_edit_number',
        langKey: 'waitForMyProcessing'
      }
    }, {
      name: 'Deal',
      path: '/work_order_deal',
      component: 'WorkOrderList/pages/All/Deal',
      meta: {
        title: '我处理的',
        icon: 'system',
        langKey: 'processByMe'
      }
    }, {
      name: 'Finish',
      path: '/WorkOrderList-finish',
      component: 'WorkOrderList/pages/All/Finish',
      meta: {
        title: '归档工单',
        icon: 'system',
        langKey: 'archiveTicket'
      }
    }, {
      name: 'WorkOrderDetail',
      path: '/work_order_detail',
      component: 'WorkOrderList/pages/detail',
      hidden: true,
      meta: {
        title: '工单详情',
        icon: 'system',
        langKey: 'ticketDetails'
      }
    }]
  }, {
    name: 'PendingWorkList',
    path: '/unsolved-WorkOrderList-all',
    component: 'Layout',
    meta: {
      title: '未解决工单',
      icon: 'example',
      langKey: 'openTicket'
    },
    children: [{
      name: 'Pending',
      path: '/work_order_pending',
      component: 'WorkOrderList/pages/All/Pending',
      meta: {
        title: '待处理',
        icon: 'system',
        // sign: true,
        many: 0,
        num: 'my_get_status1',
        langKey: 'pending'
      }
    }, {
      name: 'InHand',
      path: '/work_order_processing',
      component: 'WorkOrderList/pages/All/Processing',
      meta: {
        title: '处理中',
        icon: 'system',
        // sign: true,
        num: 'my_get_status2',
        langKey: 'process'
      }
    }, {
      name: 'ToBeConfirmed',
      path: '/work_order_unconfirmed',
      component: 'WorkOrderList/pages/All/ToBeConfirmed',
      meta: {
        title: '待确认',
        icon: 'system',
        // sign: true,
        num: 'my_get_status3',
        langKey: 'toBeConfirmed'
      }
    }]
  }, {
    name: 'WorkOrderSettingPanelAdmin',
    path: '/forum',
    component: 'Layout',
    meta: {
      title: '工单配置管理',
      icon: 'example',
      langKey: 'ticketConfigurationManagement'
    },
    children: [{
      name: 'WorkOrderSettingPanel',
      path: '/forum',
      component: 'config-manage/panel',
      meta: {
        title: '工单板块管理',
        icon: 'example',
        langKey: 'ticketSectionManagement'
      }
    }, {
      name: 'WorkOrderSettingType',
      path: '/question_set',
      component: 'config-manage/question',
      meta: {
        title: '工单问题配置',
        icon: 'example',
        langKey: 'ticketIssueManagement'
      }
    }, {
      name: 'AssignPower',
      path: '/assign_power',
      component: 'config-manage/assign-power',
      hidden: true,
      meta: {
        title: '工单权限配置',
        icon: 'system',
        langKey: 'ticketAccessManagement'
      }
    }]
  }, {
    name: 'WorkReportListAll',
    path: '/WorkReportList-all',
    component: 'Layout',
    meta: {
      title: '工单报表',
      icon: 'example',
      langKey: 'ticketReport'
    },
    children: [{
      name: 'WorkReportList',
      path: '/WorkReportList-all',
      component: 'WorkReportList/workAll',
      meta: {
        title: '工单总表',
        icon: 'system',
        langKey: 'ticketSummary'
      }
    }, {
      name: 'WorkReportListDetail',
      path: '/WorkReportList-detail',
      component: 'WorkReportList/workDetail',
      meta: {
        title: '详细报表',
        icon: 'system',
        langKey: 'detailReport'
      }
    }]
  }, {
    name: 'AccountSystemManage',
    path: '/system-accountManage-admin',
    component: 'Layout',
    meta: {
      title: '系统',
      icon: 'example',
      langKey: 'system'
    },
    children: [{
      name: 'AccountManage',
      path: '/system-accountManage',
      component: 'system/account-manage',
      meta: {
        title: '用户管理',
        icon: 'system',
        langKey: 'userManagement'
      }
    }, {
      name: 'RoleManage',
      path: '/system-roleManage',
      component: 'system/roleManage',
      meta: {
        title: '角色管理',
        icon: 'system',
        langKey: 'roleManagement'
      }
    }, {
      name: 'groupManage',
      path: '/system-group',
      component: 'system/groupManage',
      meta: {
        title: '组别管理',
        icon: 'system',
        langKey: 'groupManagement'
      }
    }, {
      name: 'Download',
      path: '/system-download',
      component: 'system/download',
      meta: {
        title: '下载管理',
        icon: 'system',
        langKey: 'downloadManagement'
      }
    }, {
      name: 'WhiteList',
      path: '/system-whiteList',
      component: 'system/whiteList',
      meta: {
        title: '白名单管理',
        icon: 'system',
        langKey: 'whitelistManagement'
      }
    }, {
      name: 'LoginLog',
      path: '/system-loginLog',
      component: 'system/loginLog',
      meta: {
        title: '登录日志',
        icon: 'system',
        langKey: 'loginLog'
      }
    }]
  }, {
    name: 'SiteJobAdmin',
    path: '/siteAdmin',
    component: 'Layout',
    meta: {
      title: '站点管理',
      icon: 'example',
      langKey: 'siteManagement'
    },
    children: [{
      name: 'SiteJob',
      path: '/siteJob',
      component: 'siteAdmin/siteJob',
      meta: {
        title: '站点分配',
        icon: 'example',
        langKey: 'siteAssignment'
      }
    }]
  }, {
    name: 'ToolAdmin',
    path: '/tool-shareDocument',
    component: 'Layout',
    meta: {
      title: '工具',
      icon: 'example',
      langKey: 'tool'
    },
    children: [{
      name: 'ShareDocument',
      path: '/tool_document',
      component: 'tool/document',
      meta: {
        title: '共享文档',
        icon: 'system',
        langKey: 'shareDocument'
      }
    }, {
      name: 'Power',
      path: '/tool-power',
      component: 'tool/power',
      meta: {
        title: '权限配置',
        icon: 'system',
        langKey: 'accessConfiguration'
      }
    }]
  }]
};
var permission = {
  state: {
    routes: [],
    addRoutes: [],
    unregulatedTotal: 0,
    isPinOver: '',
    groupList: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router2.constantRoutes.concat(routes);
    },
    SET_UNREGU_TOTAL: function SET_UNREGU_TOTAL(state, data) {
      state.unregulatedTotal = data;
    },
    SET_ISPINOVER: function SET_ISPINOVER(state, data) {
      state.isPinOver = data;
    },
    SET_GROUP_LIST: function SET_GROUP_LIST(state, data) {
      state.groupList = data;
      state.isPinOver = data;
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // 获取下拉列表
        var site_id = localStorage.getItem('siteId');
        _groupManage.default.groupSelectList({
          site_id: site_id
        }).then(function (res) {
          commit('SET_GROUP_LIST', res.data);
          // this.groupList = res.data;
        });

        // 获取置顶状态
        _WorkOrderQues.default.searchTopStatus().then(function (res) {
          if (res.code === 200) {
            commit('SET_ISPINOVER', res.data.isPinOver);
          }
        });
        var params = {
          action: 'get',
          workorder_status: 1,
          row: 10,
          page: 1,
          checked: false
        };

        // 获取待处理的数量
        _WorkOrderEntity.default.getList(params).then(function (res) {
          console.log('获取待处理数量，菜单中的');
          commit('SET_UNREGU_TOTAL', res.total);
          _router.data[1].children[0].meta.many = res.total;
          // 抛出路由设置默认路由
          var objData = {
            pid: process.env.VUE_APP_PID,
            uid: localStorage.getItem('uid')
          };
          _system.default.loginRoles(objData).then(function (res) {
            var menuData = res.data[0];
            var menuData_2 = [];
            var menuData_3 = [];
            var menuList_1 = JSON.parse(JSON.stringify(_router.data));
            // 第一次循环
            menuList_1.forEach(function (e) {
              menuData.map(function (item) {
                if (e.name === item.url) {
                  menuData_2.push(e);
                }
              });
            });

            // 第二次循环
            menuData_2.map(function (item_2) {
              if (item_2.children.length > 0) {
                menuData_3 = [];
                item_2.children.map(function (item_3) {
                  menuData.map(function (item_4) {
                    item_4.child.map(function (item_5) {
                      if (item_3.name === item_5.url) {
                        menuData_3.push(item_3);
                      }
                    });
                  });
                });
                // if (item_2.name === 'AccountAuthAdmin') {
                //   item_2.hidden = true
                // }
                item_2.children = menuData_3;
              }
            });
            // 添加动态路由
            var accessedRoutes = filterAsyncRouter(menuData_2);
            accessedRoutes.push({
              path: '*',
              redirect: '/404',
              hidden: true
            });
            commit('SET_ROUTES', accessedRoutes);
            resolve(_router2.constantRoutes.concat(accessedRoutes));
            return;
          });
        });
      });
    }
  }
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = _index.default;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}
var loadView = exports.loadView = function loadView(view) {
  // 路由懒加载
  // return () => import(`@/views/${view}`)
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
var _default = exports.default = permission;