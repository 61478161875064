"use strict";

module.exports = {
  title: '工单管理系统',
  /**
   * 是否系统布局配置
   */
  showSettings: false,
  /**
   * 是否显示 tagsView
   */
  tagsView: true,
  /**
   * 是否固定头部
   */
  fixedHeader: false,
  /**
   * 是否显示logo
   */
  sidebarLogo: true,
  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production'
};