"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Index',
  data: function data() {
    return {
      data: ['1:优化权限逻辑；', '2:修复相关bug;'],
      dataOne: ['1:优化配置逻辑;', '2:优化前端页面', '3:下载报表”新增异步处理', '4:共享文档新增删除', '5:修复相关bug'],
      dataTwo: ['1:优化“工单列表”显示逻辑;', '2:修改“豆腐块”缓存逻辑', '3:修改报表格式', '4:修复相关bug']
    };
  },
  created: function created() {},
  methods: {
    getImg: function getImg(item) {
      return require("../../assets/image/".concat(item, ".png"));
    },
    getImg4: function getImg4(item) {
      return require("../../assets/image/".concat(item, ".jpg"));
    }
  }
};