"use strict";

var _interopRequireDefault = require("/build-app/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _whiteList = _interopRequireDefault(require("@services/whiteList"));
var _store = _interopRequireDefault(require("@/store"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {},
  props: {
    formid: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialogFlag: false,
      form: {
        code: '',
        password: '',
        again_pwd: '',
        token: localStorage.getItem('token')
      },
      titleName: this.$t('resetPassword'),
      // 保存登录密码
      password: localStorage.getItem('password'),
      rules: {
        code: [{
          required: true,
          message: this.$t('pleaseInputOldPassword'),
          trigger: 'blur'
        }, {
          min: 6,
          max: 50,
          message: this.$t('lenthRange6to26'),
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: this.$t('pleaseInputNewPassword'),
          trigger: 'blur'
        }, {
          min: 8,
          max: 50,
          message: this.$t('lenthRange8to26'),
          trigger: 'blur'
        }],
        again_pwd: [{
          required: true,
          message: this.$t('pleaseInputConfirmPassword'),
          trigger: 'blur'
        }, {
          min: 8,
          max: 50,
          message: this.$t('lenthRange8to26'),
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    open: function open() {
      this.dialogFlag = true;
    },
    close: function close() {
      this.dialogFlag = false;
      this.form = {};
    },
    confirm: function confirm() {
      var _this = this;
      // return
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this.form.code !== _this.password) {
            return _this.$message.error(_this.$t('oldPasswordError'));
          }
          if (_this.form.password !== _this.form.again_pwd) {
            return _this.$message.error(_this.$t('time2NotSame'));
          }
          _whiteList.default.editpwd(_this.form).then(function (res) {
            if (res.code !== 0) {
              return _this.$message.error(res.msg);
            }
            _this.$message.success(_this.$t('editSuccess'));
            _store.default.dispatch('LogOut').then(function () {
              location.reload();
            });
          }).catch();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};